import React from "react";
import ContactHeroPalm from "../../assets/others/ContactHeroPalm.jpg";
import { contactIconData } from "../../data/ContactIconData.js";

function HeroContact() {
  return (
    <div className="mt-[75px]">
      <div className="bg-slate-100 w-full">
        <div
          className="h-[520px] md:h-[350px] bg-cover"
          style={{ backgroundImage: `url(${ContactHeroPalm}` }}
        >
          <div className="bg-black/30 flex justify-center items-center h-full w-full">
            <h1 className="text-4xl md:text-5xl lg:text-6xl text-white font-bold">
              Contact Us
            </h1>
          </div>
        </div>

        <div className="w-10/12 max-w-[1100px] md:-mt-[100px] pb-16 mx-auto md:px-10 bg-green-950">
          <p className="text-[45px] text-center font-serif text-purple-50 font-bold pt-[70p/x] py-/5">
            Feel free to contact us.
            <br />
            We are here to answer
            <br className="hidden md:block" />
            all your questions
          </p>

          <div className="flex flex-col md:flex-row gap-10 justify-center">
            <div className="mt-[30px] text-center">
              <h2 className="font-bold text-2xl mx-auto md:text-base border-b border-lime-500 w-fit pb-1 text-purple-50">
                Monday - Saturday
              </h2>

              <p className="text-4xl text-lime-500">8am - 4pm</p>
            </div>
            <div className="mt-[30px] text-center">
              <h2 className="font-bold text-2xl mx-auto md:text-base border-b w-fit border-lime-500 pb-1 text-purple-200">
                Sunday
              </h2>
              <p className="text-4xl text-lime-500">9am - 3pm</p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-[50px] mt-[70px] mb-1/6">
            {contactIconData.map((data, index) => {
              return (
                <div
                  key={index}
                  className="bg-green-950 p-4 text-center md:h-[100/px] w-full"
                >
                  <img
                    src={data.image}
                    aria-hidden="true"
                    alt=""
                    className="w-[50px] h-[50/px] mx-auto"
                  />
                  <h3 className="font-bold text-3xl mt-[30px] text-purple-50 font-serif">
                    {data.title}
                  </h3>
                  <p className="text-purple-50 mt-[10px]">{data.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full max-w-[1100px] h-[900px] px-10 py-20 mx-auto">
          <iframe
            width="100%"
            height="600"
            title="Oracleous-Map"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=MS2%20Pent%20house,%20Block%204%20Malberry%20street,%20Michvillle%20estate,%20Lokogoma,%20Abuja+(Oracleous%20Enterprises)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.gps.ie/">gps trackers</a>
          </iframe>
        </div>
      </div>
    </div>
  );
}

export default HeroContact;
