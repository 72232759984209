import React from "react";
import { Link } from "react-router-dom";
import oracleous from "../../assets/logo/OracleousLogo.png";

function Footer() {
  return (
    <>
      <footer className="mt-10 md:mt-16 px-10 md:px-16">
        <div className="flex flex-col md:flex-row gap-10 justify-between">
          <div className="ml-4 md:ml-0 mt-[-10px]">
            <img src={oracleous} alt="oracleuos logo" />
          </div>
          {/* contact us */}
          <div className="">
            <h5 className="text-lg font-serif">
              <span className="font-bold">Contact us</span>
              <br />
              Contact us to receive full details and
              <br /> learn more about our products and services!
            </h5>
            <div className="mt-6">
              <Link
                to="/contact"
                className="py-3 px-9 text-slate-200 bg-lime-800 hover:bg-lime-600"
              >
                Read more
              </Link>
            </div>

            <div className="flex space-x-[25px] mt-10 text-lime-800">
              <Link to="/contact" className="hover:underline">
                FAQ
              </Link>
              <Link to="/contact" className="hover:underline">
                Support
              </Link>
              <Link to="/contact" className="hover:underline">
                Useful info
              </Link>
            </div>
          </div>

          {/* div 2 */}
          {/* mission statement */}
          <div className="">
            <h5 className="text-[18px] font-bold">Our mission</h5>
            <p className="">
              To modernize palm oil processing by
              <br /> introducing efficient processing techniques,
              <br /> increasing production while
              <br /> maintaining sustainable practices
            </p>
            <div className="mt-6">
              <Link href="/about" className="text-lime-800 hover:underline">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <div className="text-center my-10 text-base font-bold">
        <p>
          Powered by{" "}
          <Link to="/" className="text-lime-800 hover:underline">
            Spring Core Africa{" "}
          </Link>
        </p>
      </div>
    </>
  );
}

export default Footer;
