import React from "react";
import RedPalm from "../../assets/ourExperience/Red-Palm-.jpg";
import PalmAgr from "../../assets/ourExperience/PalmAgr.jpg";
import shutter from "../../assets/ourExperience/shutterstock.webp";
import white from "../../assets/ourExperience/whitePalm.jpg";
import PalmPeople from "../../assets/ourExperience/PalmPeople.jpg";
import PalmHand from "../../assets/ourExperience/pamlHand.jpg";

function ourExperiences() {
  return (
    <div className="h-[200px] mt-[30px] w-full bg-slate-50">
      <div className="overflow-auto whitespace-nowrap mt-[50px]">
        <div className="flex  gap-[20px] animate-marquee">
          <img
            src={RedPalm}
            alt="fresh palm kernels beside a cup of palm oil"
            className="w-[250px] h-[250px] "
          />
          <img
            src={PalmAgr}
            alt="two men with cart harvesting palm produce"
            className="w-[250px] h-[250px]"
          />
          <img
            src={shutter}
            alt="fresh palm in a basket beside a nice extract"
            className="w-[250px] h-[250px]"
          />
          <img
            src={white}
            alt="fresh palm in a basket beside a nice extract"
            className="w-[250px] h-[250px]"
          />
          <img
            src={PalmPeople}
            alt="farmers returning from the farm with palm produce on their head"
            className="w-[250px] h-[250px]"
          />
          <img
            src={PalmHand}
            alt="hand holding a freshly plucked palm kernel"
            className="w-[250px] h-[250px]"
          />
        </div>
      </div>
    </div>
  );
}

export default ourExperiences;
