import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/general/Footer";
import TopNav from "../components/general/TopNav";
import HomeHero from "../components/homePage/HomeHero";
import OurExperiences from "../components/homePage/ourExperiences";
import WhyUs from "../components/homePage/WhyUs";

function HomePage() {
  const location = useLocation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="max-w-[1440px] mx-auto">
      <TopNav location={location.pathname} />
      <HomeHero />
      <OurExperiences />
      <WhyUs />
      <Footer />
    </div>
  );
}

export default HomePage;
