import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TopNav from "../components/general/TopNav";
import OurStoryHero from "../components/ourStory/OurStoryHero";
import Footer from "../components/general/Footer";

function OurStoryPage() {
  const location = useLocation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="max-w-[1440px] mx-auto">
      <TopNav location={location.pathname} />
      <OurStoryHero />
      <Footer />
    </div>
  );
}

export default OurStoryPage;
