import { Link } from "react-router-dom";
import WhyUsPalm from "../../assets/whyUs/whyUsPalm.jpg";
import Daniel from "../../assets/others/Daniel.jpg";
import Martins from "../../assets/others/Martins.png";
import Samuel from "../../assets/others/Samuel.jpg";
import Footer from "../general/Footer";

function AboutUsHero() {
  return (
    <div className="mt-[75px] bg-slate-200">
      <div className="w-full bg-green-900 py-10 px-10 md:px-16">
        <div className="flex flex-col lg:flex-row lg:items-center gap-6">
          <div className="w-full">
            <h1 className="text-3xl lg:text-4xl xl:text-5xl leading-[55px] font-bold w-full text-purple-50 py-10">
              We aim at modernizing palm oil processing by introducing efficient
              processing techniques, increasing production while maintaining
              sustainable practices
            </h1>

            <Link
              to="/our-story"
              className="flex items-center justify-center text-white h-[60px] w-[250px] bg-lime-600 hover:bg-lime-500"
            >
              Read more
            </Link>
          </div>
          <img
            src={WhyUsPalm}
            alt="hand selecting fresh palm fruits"
            className="hidden md:block w-full"
          />
        </div>
      </div>

      <h1 className="font-serif text-3xl md:text-4xl lg:text-5xl text-6xl text-center mt-10 mb-20 md:mb-24 text-green-900 py-4">
        Meet our team
      </h1>

      <div className="flex flex-col md:flex-row gap-20 md:justify-between bg-slate-200 w-full pb-9 md:pb-16 px-9 md:px-16">
        <div className="bg-green-950 border border-white text-center px-6 pb-6 w-full">
          <img
            src={Daniel}
            alt="Daniel Chukwu"
            className="mt-[-40px] mx-auto border-[10px] border-green-950 w-4/5"
          />
          <p className="text-2xl font-serif text-white mt-[40px] font-bold">
            Daniel Chukwu
          </p>
          <p className="text-white">
            Founder and CEO <br />
            Leap Africa Fellow <br />
            MBA
          </p>
        </div>
        <div className="bg-green-950 border border-white text-center px-6 pb-6 w-full">
          <img
            src={Martins}
            alt="Tavershima Martins"
            className="mt-[-40px] mx-auto border-[10px] border-green-950 w-4/5"
          />
          <p className="text-2xl font-serif text-white mt-[40px] font-bold">
            Tavershima Martins
          </p>
          <p className="text-white">
            Co-founder and COO <br />
            Marketing Strategist
          </p>
        </div>
        <div className="bg-green-950 border border-white text-center px-6 pb-6 w-full">
          <img
            src={Samuel}
            alt="Nwachukwu Samuel"
            className="mt-[-40px] mx-auto border-[10px] border-green-950 w-4/5"
          />
          <h3 className="text-2xl font-serif text-white mt-[40px] font-bold">
            Nwachukwu Samuel
          </h3>
          <p className="text-white">CTO</p>
        </div>
      </div>

      <div className="bg-purple-200">
        <div className="py-10">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AboutUsHero;
